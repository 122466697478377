import $http from './index'

//  查询 || 获取缴费列表

export function payList(page, rows, buildingid, room_name, month, user_name, status) {
  return $http.get('/PayManagement/searchMonthlyBillGson', {
    params: {
      page: page,
      rows: rows,
      buildingid: buildingid,
      room_name: room_name,
      month: month,
      user_name: user_name,
      status: status
    }
  })
}

// 生成物业缴费记录

export function postJiaofeijilu(datetime) {
  return $http.post('/PayManagement/CreateMonthlyBill', {
    datetime: datetime
  })
}


export function postJiaofeiYue(month) {
  return $http.post('/PayManagement/GetOrderspayGson', {
    month: month
  })
}

//导出缴费日
export function postJfDay(day) {
  return $http.post('/PayManagement/GetPrintDayCount', {
    day: day
  })
}

//缴费通知发送

export function jiaofeiSend(month, action) {
  return $http.post('/PayManagement/NotifySend', {
    month,
    action
  })
}

//现金缴费

export function cashPayment(id, month, totalfee, roomName) {
  return $http.post('/PayManagement/OfflinePayment', {
    id,
    month,
    totalfee,
    roomName
  })
}

//缴费发布

export function postjiaofeifabu(datetime) {
  return $http.post('/PayManagement/UpdateMonthlyBillPl', {
    datetime
  })
}

//获取打印水费

export function getWater(MonthOrderID,month){
  return $http.get('/PayManagement/GetWaterInfo',{
      params:{
          MonthOrderID,
          month
      }
  })
}

//获取物业单价等

export function getPrice(moId){
  return $http.get('/PayManagement/GetConsoleUnitPriceWaterInfo',{
    params:{
      moId
    }
  })
}


// 单据查询
export function getDanju(transactionId){
  return $http.get('/PayManagement/GetMonthOrderByTransactionId',{
    params:{
      transactionId
    }
  })
}

//手动预缴费
export function postShoudongyjf(id,oldFree,newFree){
  return $http.post('/PayManagement/SetYuJiaoFree',{
    id,
    oldFree,
    newFree
  })
}

//代扣记录导出

export function dkjiluExport(month,method){
  return $http.post('/PayManagement/GetDKFile',{
    month,
    method
  })
}


//前台导出预交费 赠送缴费  预缴物业费

export function QTexport(month,method){
  return $http.post('/PayManagement/GetOrdersMonth',{
    month,
    method
  })
}